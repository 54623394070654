import React from 'react';
import icon1 from '../../assets/images/l-icon1.svg';
import icon2 from '../../assets/images/l-icon2.svg';
import icon3 from '../../assets/images/l-icon3.svg';
import icon4 from '../../assets/images/l-icon4.svg';

export default function Leistungen() {
    return (
        <section className="leist" id="leist">
            <div className="container">
                <div className="row row-sec-head row1">
                    <div className="xs12">
                        <h3>Leistungen</h3>
                        <div className="desc">Wir bieten Ihnen bedarfsorientierte Sicherheitslösungen</div>
                    </div>
                </div>
                <div className="row row2">
                    <div className="sm6 xs12 le-item">
                        <img src={icon1} alt="" />
                        <h5>Objekt-, Brand- und Werkschutz</h5>
                        <p>Unsere erfahrenen Experten übernehmen beim Objekt- und Werkschutz die Gesamtkoordination des Sicherheitskonzeptes. Dabei werden diverse Einzelleistungen wie Vorkontrollen, Wachschutz, Bestreifung und Observation, Zutrittskontrolle oder Identitätsprüfung nach Ihren Bedürfnissen eingesetzt.</p>
                    </div>
                    <div className="sm6 xs12 le-item">
                        <img src={icon2} alt="" />
                        <h5>Event- und Veranstaltungsschutz</h5>
                        <p>Die professionelle Eventsicherung der Buchbinder Security sorgt für einen reibungslosen Ablauf Ihrer Events. Unser Personal verfügt über langjährige Erfahrungen im Veranstaltungsschutz, die notwendige Diskretion und gepflegte Umgangsformen sind dabei selbstverständlich. Bei Bedarf stellen wir Ihnen gerne Event-Personal und Begleitservice zur Verfügung.</p>
                    </div>
                    <div className="sm6 xs12 le-item">
                        <img src={icon3} alt="" />
                        <h5>Fahrdienste</h5>
                        <p>Wir legen hohen Wert auf Qualität, Service und rundum Komfort für unsere Fahrgäste. Unser speziell auf Sicherheitsaspekte geschultes Fahrpersonal befördert unseren Kunden – ob geschäftlich oder privat - termingerecht, sicher und diskret zu Ihren Zielen. Falls erforderlich, auch bewaffnet.</p>
                    </div>
                    <div className="sm6 xs12 le-item">
                        <img src={icon4} alt="" />
                        <h5>Individueller Begleitschutz</h5>
                        <p>Unsere kompetenten Begleitschützer agieren unauffällig, um Gefahren abzuwehren und zu verhindern. Sie erhalten Schutz vor Bedrohungen und Angriffen gegen Ihr Leben, Ihr Image oder Ihre Handlungsfreiheit. Für Ihre Familienangehörigen bieten wir Begleitschutz auf dem Schulweg und in der Freizeit an.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}