import React from 'react';
import { Link } from "react-router-dom";
import Header from '../components/Header';
import Form from '../components/Form';
import Footer from '../components/Footer';

//Modals
import ModalBig from '../modals/ModalBig';

import arrow from '../../assets/images/arrow.svg';

export default class Impressum extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isBigFormSent: false
        }

        this.sendBigForm = this.sendBigForm.bind(this);
        this.closeBigForm = this.closeBigForm.bind(this);
    }

    render() {
        return(
            <div className="text-page">
                <Header isHomePage={false} isPdf={false}/>
                <section className="impressum">
                    <div className="container">
                        <div className="row">
                            <div className="lg2 md1 sm0 xs0"></div>
                            <div className="lg5 md5 sm6 xs12 text-col">
                                <Link to="/" className="link-back">
                                    <img src={arrow} alt="" />
                                    <span>Zurück</span>
                                </Link>
                                <h5>Impressum</h5>
                                <p>Angaben gemäß § 5 TMG:</p>
                                <p>Buchbinder Security Management UG (haftungsbeschränkt)</p>
                                <p>
                                    Gartenstraße 3
                                    <br />
                                    14482 Potsdam
                                </p>
                                <p>
                                    E-Mail: &nbsp;
                                    <a href="mailto:info@buchbinder-security.de">
                                        info@buchbinder-security.de
                                    </a>
                                    <br />
                                    Telefon: &nbsp;
                                    <a href="tel:+49033160049480">+49 (0) 331 600 494 80</a>
                                    <br />
                                    Telefax: &nbsp;
                                    <a href="tel:+49033160049481">+49 (0) 331 600 494 81</a>
                                </p>
                                <p>
                                    Sitz der Gesellschaft: Potsdam
                                    <br />
                                    Amtsgericht Potsdam: HRB 32526 P
                                    <br />
                                    Geschäftsführer:  Jewgeny Buchbinder
                                </p>
                                <p>Umsatzsteuer-ID: DE324728392</p>
                            </div>
                            <div className="lg4 md4 sm6 xs12">
                                <Form sendBigForm={this.props.sendBigForm}/>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer sendLilForm={this.sendLilForm}/>
                {this.state.isBigFormSent ? <ModalBig closeBigForm={this.closeBigForm}/> : null}
            </div>
        )
    }

    sendBigForm(e) {
        e.preventDefault();
        console.log('big f s');
        this.setState({
            isBigFormSent: true
        });
    }

    closeBigForm(e) {
        e.preventDefault();
        this.setState({
            isBigFormSent: false
        });
    }
}