import React from 'react';
import { Link } from 'react-router-dom';
import LilForm from './LilForm.js'
import logo from '../../assets/images/logo-b.svg';

export default function Footer(props) {

    return (
        <footer>
            <div className="container">
                <div className="row row1">
                    <div className="lg2 md2 sm2 xs12 logo-box">
                        <Link to="/">
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                    <div className="xl0 lg0 md0 sm0 xs12 form-box">
                        <h6>Schnellanruf bestellen</h6>
                        <p>Hinterlassen Sie Ihre Nummer, wir rufen Sie zurück</p>
                        <LilForm sendLilForm={props.sendLilForm}/>
                    </div>
                    <div className="lg3 md3 sm3 xs0 info-box">
                        <h6>Seitenverzeichnis</h6>
                        <p><a href="/#leist" className="menu-item">Dienstleistungen</a></p>
                        <p><a href="/#warum" className="menu-item">Warum wir</a></p>
                        <p><a href="/#team" className="menu-item">Unser Team</a></p>
                        <p><a href="/#uber" className="menu-item">Über uns</a></p>
                        <p><a href="/#kontakt" className="menu-item">Kontakt</a></p>
                    </div>
                    <div className="lg3 md3 sm3 xs12 info-box">
                        <h6>Kontakte</h6>
                        <p><a href="mailto:info@buchbinder-security.de">info@buchbinder-security.de</a></p>
                        <p><a href="tel:+49033160049480">+49 (0) 331 600 494 80</a></p>
                        <p>
                            Gartenstraße 3 <br />14482 Potsdam
                        </p>
                    </div>
                    <div className="xl0 lg0 md0 sm0 xs12 info-box">
                        <h6>Seitenverzeichnis</h6>
                        <p><Link to="/#leist">Dienstleistungen</Link></p>
                        <p><Link to="/#warum">Warum wir</Link></p>
                        <p><Link to="/#team">Unser Team</Link></p>
                        <p><Link to="/#uber">Über uns</Link></p>
                        <p><Link to="/#kontakt">Kontakt</Link></p>
                    </div>
                    <div className="lg4 md4 sm4 xs0 form-box">
                        <h6>Schnellanruf bestellen</h6>
                        <p>Hinterlassen Sie Ihre Nummer, wir rufen Sie zurück</p>
                        <LilForm sendLilForm={props.sendLilForm}/>
                    </div>
                </div>
                <div className="row row2">
                    <div className="md6 sm4 xs0">© {(new Date().getFullYear())} Buchbinder Security</div>
                    <div className="md6 sm8 xs12 links-box">
                        <Link to="/datenschutz/">Datenschutz</Link>
                        <Link to="/impressum/">Impressum</Link>
                        <br className="xl0 lg0 md0 sm0" />
                        <Link to="/allgemeine/">Allgemeine Geschäftsbedingungen</Link>
                    </div>
                    <div className="xl0 lg0 md0 sm0 xs12">© {(new Date().getFullYear())} Buchbinder Security</div>
                </div>
            </div>
        </footer>
    )
}