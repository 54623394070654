import React from 'react';
import Form from './Form';
import icon1 from '../../assets/images/k-icon1.svg';
import icon2 from '../../assets/images/k-icon2.svg';
import icon3 from '../../assets/images/k-icon3.svg';

export default function Contact(props) {
    return (
        <section className="contact" id="kontakt">
            <div className="container">
                <div className="row row-sec-head row1">
                    <div className="xs12">
                        <h3>KONTAKT</h3>
                        <div className="desc">Gerne beraten wir Sie persönlich und erstellen Ihnen ein individuelles Angebot.</div>
                    </div>
                </div>
            </div>
            <div className="full">
                <div className="container">
                    <div className="abs1">
                        <h5>Buchbinder Security Management UG (haftungsbeschränkt)</h5>
                        <div className="info-item">
                            <div className="img-col">
                                <img src={icon1} alt="" />
                                </div>
                            <div className="text-col">
                                <h6>Bürostandort</h6>
                                <p>
                                    Gartenstraße 3
                                    <br />
                                    14482 Potsdam
                                </p>
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="img-col">
                                <img src={icon2} alt="" />
                                </div>
                            <div className="text-col">
                                <h6>Telefon</h6>
                                <p>
                                    <a href="tel:+49033160049480">Tel +49 (0) 331 600 494 80</a>
                                <br />
                                    <a href="tel:+49033160049481">Fax +49 (0) 331 600 494 81</a>
                                </p>
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="img-col">
                                <img src={icon3} alt="" />
                                </div>
                            <div className="text-col">
                                <h6>Email</h6>
                                <p>
                                    <a href="mailto:info@buchbinder-security.de">
                                        info@buchbinder-security.de
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="abs2 xs0">
                        <h4>Vertraulichkeit und Loyalität bilden das Fundament unserer Arbeit.</h4>
                    </div>
                </div>
            </div>
            <div className="container container3">
                <div className="row">
                    <div className="sm6 xs12 form-col" id="form">
                        <Form sendBigForm={props.sendBigForm}/>
                    </div>
                    <div className="sm6 xs12">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9740.93297399329!2d13.104422814819351!3d52.38432193629789!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a85f58d9c3dcff%3A0xb49825ecc30f4603!2sBuchbinder%20Security%20Management%20UG%20(haftungsbeschr%C3%A4nkt)!5e0!3m2!1sde!2sus!4v1579796438545!5m2!1sde!2sus" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </section>
    )
}