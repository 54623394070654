//Libs
import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

//Components
import Home from './modules/pages/Home';
import Text from './modules/pages/Text';
import Impressum from './modules/pages/Impressum';

//Styles
import './App.scss';

export default class App extends React.Component {
  constructor() {
    super();
    
    this.state = {
      
    }
  }
  render() {
    return (
      <Router>
        <Route path="/" exact component={Home} />
        <Route path="/datenschutz/" component={Text} />
        <Route path="/allgemeine/" component={Text} />
        <Route path="/impressum/" component={Impressum} />
      </Router>
    );
  }
}