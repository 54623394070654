import React from 'react';
import axios from 'axios';

export default class Form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.sendForm = this.sendForm.bind(this);
    }

    sendForm(e) {
        e.preventDefault();

        const form = e.target.parentElement;
        if (form.name.value === '') {
            form.name.classList.add('error');

            setTimeout(() => {
                form.name.classList.remove('error');
            }, 2500)

            return;
        } else if (form.email.value === '') {
            form.email.classList.add('error');

            setTimeout(() => {
                form.email.classList.remove('error');
            }, 2500)

            return;
        } else if (form.telefon.value === '') {
            form.telefon.classList.add('error');

            setTimeout(() => {
                form.telefon.classList.remove('error');
            }, 2500)

            return;
        } else if (form.message.value === '') {
            form.message.classList.add('error');

            setTimeout(() => {
                form.message.classList.remove('error');
            }, 2500)

            return;
        }
        axios({
          method: 'post',
          url: '/php/form.php',
          data: {
            name: form.name.value,
            phone: form.telefon.value,
            email: form.email.value,
            message: form.message.value
          }
        })
        
        this.props.sendBigForm(e);

    }

    render() {
        return (
            <form className="big-form">
                <div className="form-header">Kontaktieren Sie uns</div>
                <label>
                    <span>Name</span>
                    <input type="text" name="name" placeholder="Vollständiger Name" />
                </label>
                <label>
                    <span>E-Mail</span>
                    <input type="text" name="email" placeholder="Geben Sie Ihre E-Mail Adresse ein" />
                </label>
                <label>
                    <span>Telefon</span>
                    <input type="text" name="telefon" placeholder="Geben Sie eine Telefonnummer ein" />
                </label>
                <label>
                    <span>Nachricht</span>
                    <textarea name="message" placeholder="Geben Sie Ihre Nachricht ein" />
                </label>
                <input type="submit" value="Bestellen Sie uns" onClick={this.sendForm}/>
            </form>
        )
    }
}