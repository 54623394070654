import React from 'react';
import axios from 'axios';

export default class LilForm extends React.Component {
	constructor(props) {
        super(props);

        this.state = {

        }

        this.sendForm = this.sendForm.bind(this);
    }

	sendForm(e) {
        e.preventDefault();

        const form = e.target.parentElement;
        if(form.phone.value === '') {
        	form.phone.classList.add('error');

        	setTimeout(() => {
        		form.phone.classList.remove('error');
        	}, 2500)

        	return;
        }
        axios({
		  method: 'post',
		  url: '/php/form.php',
		  data: {
		    phone: form.phone.value,
		  }
		})
        
        this.props.sendLilForm(e);
    }

    render() {
	    return (
	        <form className="lil-form">
	            <input type="text" id="phone" placeholder="Geben Sie eine Telefonnummer ein" required />
	            <input type="submit" value="senden" onClick={this.sendForm}/>
	        </form>
	    )
	}
}