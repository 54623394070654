import React from 'react';
import cross from '../../assets/images/cross.svg';
import conversation from '../../assets/images/conversation.svg';
 
export default function ModalBig(props) {
	return (
		<div className="modal modal-overlay">
			<div className="modal-win modal-big">
				<div className="cross">
					<img src={cross} alt="" onClick={props.closeBigForm} />
				</div>
				<div className="icon-box">
					<img src={conversation} alt="" />
				</div>
				<h3>Vielen Dank für Ihre Kontaktaufnahme</h3>
				<div className="desc">
					Ihre Anfrage wurde versendet. Buchbinder Team meldet sich in Kürze bei Ihnen.
				</div>
				<a href="/" className="button" onClick={props.closeLilForm}>zurück zur Homepage</a>
			</div>
		</div>
	)
}