import React from 'react';

export default function Uberlassen() {
    return (
        <section className="uberlassen" id="uber">
            <h3>
                Überlassen Sie Ihre Sicherheit nicht dem &nbsp;
                <br className="xs0" />
                Zufall sondern den Experten von der &nbsp;
                <br className="xs0" />
                Buchbinder Security
            </h3>
        </section>
    )
}