import React from 'react';

//Components
import Header from '../components/Header';
import FirstScreen from '../components/FirstScreen';
import Advantages from '../components/Advantages';
import Leistungen from '../components/Leistungen';
import UberUns from '../components/UberUns';
import Team from '../components/Team';
import Uberlassen from '../components/Uberlassen';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

//Modals
import ModalBig from '../modals/ModalBig';
import ModalLil from '../modals/ModalLil';

export default class Impressum extends React.Component {
    constructor() {
        super();

        this.state = {
            isBigFormSent: false,
            isLilFormSent: false
        }

        this.sendBigForm = this.sendBigForm.bind(this);
        this.sendLilForm = this.sendLilForm.bind(this);
        this.closeBigForm = this.closeBigForm.bind(this);
        this.closeLilForm = this.closeLilForm.bind(this);
    }

    render() {
        return (
            <div>
                <Header isPdf={false} isHomePage="true" />
                <FirstScreen />
                <Advantages />
                <Leistungen />
                <UberUns sendLilForm={this.sendLilForm}/>
                <Team />
                <Uberlassen />
                <Contact sendBigForm={this.sendBigForm}/>
                <Footer sendLilForm={this.sendLilForm}/>
                {this.state.isBigFormSent ? <ModalBig closeBigForm={this.closeBigForm}/> : null}
                {this.state.isLilFormSent ? <ModalLil closeLilForm={this.closeLilForm}/> : null}
            </div>
        )
    }

    sendBigForm(e) {
        e.preventDefault();
        this.setState({
            isBigFormSent: true
        });
    }

    sendLilForm(e) {
        e.preventDefault();
        this.setState({
            isLilFormSent: true
        });
    }

    closeBigForm(e) {
        e.preventDefault();
        this.setState({
            isBigFormSent: false
        });
    }

    closeLilForm(e) {
        e.preventDefault();
        this.setState({
            isLilFormSent: false
        });
    }
}