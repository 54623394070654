import React from 'react';

export default function Datenschutz() {
	return (
		<div className="text-box">
			<h5>Buchbinder Security Datenschutzbestimmungen</h5>
			<h6>Einführung</h6>
			<p>
				In diesen Datenschutzbestimmungen stellt Buchbinder Security („wir“, „unser“ oder „das Unternehmen“) seine Vorgehensweise bezüglich der von Benutzern erfassten Daten, die auf unsere Webseite unter buchbinder-security.de („Webseite“) zugreifen oder uns auf andere Weise personenbezogene Daten bereitstellen (gemeinsam: „Benutzer“), dar. 
			</p>
			<h6>Grundlage für die Datenerfassung </h6>
			<p>
				Die Verarbeitung Ihrer personenbezogenen Daten (d. h. jegliche Daten, die mit vertretbaren Mitteln eine Identifizierung Ihrer Person zulassen; „personenbezogene Daten“) ist erforderlich, um unseren vertraglichen Verpflichtungen Ihnen gegenüber nachzukommen und damit wir Ihnen unsere Services bereitstellen, unser legitimes Interesse schützen sowie rechtlichen und finanziellen Regulierungsverpflichtungen, denen wir unterworfen sind, nachkommen können.
				<br />
				<br />
				Durch die Nutzung dieser Webseite stimmen Sie der Erfassung, Speicherung, Verwendung, Offenlegung und sonstigen Nutzung Ihrer personenbezogenen Daten wie in diesen Datenschutzbestimmungen beschrieben zu.
				<br />
				<br />
				Bitte lesen Sie sich die Datenschutzbestimmungen sorgfältig durch, bevor Sie Entscheidungen treffen. 
			</p>
			<h6>Welche Daten werden erfasst?</h6>
			<p>
				Wir erfassen zwei Arten von Daten und Informationen von Benutzern.
				<br />
				<br />
				Zur ersten Kategorie gehören nicht identifizierende und nicht identifizierbare Benutzerdaten, die durch die Nutzung der Webseite bereitgestellt oder erfasst werden („Nicht personenbezogene Daten“). Wir kennen die Identität des Benutzers nicht, von dem nicht personenbezogene Daten erfasst wurden. Zu den nicht personenbezogenen Daten, die erfasst werden können, gehören aggregierte Nutzungsdaten und technische Daten, die von Ihrem Gerät übermittelt werden, einschließlich bestimmter Informationen bezüglich Software und Hardware (z. B. auf dem Gerät verwendeter Browser und verwendetes Betriebssystem, Spracheinstellung, Zugriffszeit usw.). Anhand dieser Daten verbessern wir die Funktionalität unserer Webseite. Wir können auch Daten über Ihre Aktivität auf der Webseite erfassen (z. B. aufgerufene Seiten, Surfverhalten, Klicks, Aktionen usw.).
				<br />
				<br />
				Zur zweiten Kategorie gehören personenbezogene Daten , also Daten, die eine Einzelperson identifizieren oder durch angemessene Maßnahmen identifizieren können. Zu solchen Daten gehören:
			</p>
			<div className="offset">
				Gerätedaten: Wir erfassen personenbezogene Daten von Ihrem Gerät. Solche Daten umfassen Geolocation-Daten, IP-Adresse, eindeutige Kennungen (z. B. MAC-Adresse und UUID) sowie andere Daten, die sich aus Ihrer Aktivität auf der Webseite ergeben.
			</div>
			<h6>Wie erhalten wir Daten über Sie?</h6>
			<p>Wir beziehen Ihre personenbezogenen Daten aus verschiedenen Quellen:</p>
			<ul>
				<li>Sie stellen uns solche Daten freiwillig bereit, zum Beispiel bei der Registrierung auf unserer Webseite.</li>
				<li>Wir erhalten solche Daten, wenn Sie unsere Webseite nutzen oder in Verbindung mit einem unserer Services darauf zugreifen.</li>
				<li>Wir erhalten solche Daten von anderen Anbietern, Services und aus öffentlichen Registern (zum Beispiel von Datentraffic-Analyseanbietern).</li>
			</ul>
			<h6>Welche Daten werden erfasst?</h6>
			<p>
				Wir geben Benutzerdaten nicht an Dritte weiter, außer wie in diesen Datenschutzbestimmungen beschrieben.
				<br />
				<br />
				Wir verwenden Daten für folgende Zwecke:
			</p>
			<ul>
				<li>Zur Kommunikation mit Ihnen (Senden von Hinweisen bezüglich unserer Services, Bereitstellen von technischen Informationen und ggf. Bearbeiten von Kundendienstanfragen)</li>
				<li>Zur Information über neue Updates und Services</li>
				<li>Zur Anzeigenschaltung im Rahmen der Nutzung unserer Webseite (weitere Informationen unter „Werbung“) </li>
				<li>Zur Vermarktung unserer Webseiten und Produkte (weitere Informationen unter „Marketing“) </li>
				<li>Zu statistischen und Analysezwecken, die der Verbesserung der Webseite dienen</li>
			</ul>
			<p>
				Neben den verschiedenen, oben aufgeführten Verwendungszwecken können wir personenbezogene Daten auch an unsere Tochtergesellschaften, verbundenen Unternehmen und Subunternehmer weitergeben.
				<br />
				<br />
				Zusätzlich zu den in diesen Datenschutzbestimmungen aufgeführten Zwecken können wir personenbezogene Daten aus folgenden Gründen an unsere vertrauenswürdigen externen Anbieter weiterleiten, die ihren Sitz in unterschiedlichen Rechtsprechungen auf der ganzen Welt haben: 
			</p>
			<ul>
				<li>Hosten und Betreiben unserer Webseite</li>
				<li>Bereitstellen unserer Services, einschließlich der personalisierten Anzeige unserer Webseite</li>
				<li>Speichern und Verarbeiten solcher Daten in unserem Namen </li>
				<li>Schalten von Anzeigen sowie die Möglichkeit, den Erfolg unserer Werbekampagnen zu beurteilen, Retargeting von Benutzern</li>
				<li>Bereitstellen von Marketingangeboten und Werbematerialien in Zusammenhang mit unserer Webseite und unseren Services</li>
				<li>Durchführen von Studien, technischen Diagnosen oder Analysen</li>
			</ul>
			<p>
				Wir können Daten auch offenlegen, wenn wir im guten Glauben sind, dies ist hilfreich oder angemessen, um: (i) geltenden Gesetzen, Vorschriften, Gerichtsverfahren oder behördlichen Anfragen zu entsprechen; (ii) unsere Richtlinien (einschließlich unserer Vereinbarung) durchzusetzen und ggf. diesbezügliche mögliche Verletzungen zu untersuchen; (iii) illegale Aktivitäten oder anderes Fehlverhalten, Betrugsverdacht oder Sicherheitsprobleme zu untersuchen, zu erkennen, zu verhindern oder Maßnahmen dagegen zu ergreifen; (iv) eigene Rechtsansprüche geltend zu machen oder durchzusetzen bzw. uns gegen die Ansprüche anderer zu verteidigen; (v) die Rechte, das Eigentum oder unsere Sicherheit, die Sicherheit unserer Benutzer, Ihre Sicherheit oder die Sicherheit von Dritten zu schützen; oder um (vi) mit Strafverfolgungsbehörden zusammenzuarbeiten und/oder geistiges Eigentum oder andere Rechtsansprüche zu schützen.
			</p>
			<h6>Benutzerrechte</h6>
			<p>
				Sie haben folgende Rechte: 
			</p>
			<ol>
				<li>Eine Bestätigung, ob und inwieweit Ihre personenbezogenen Daten verwendet und verarbeitet werden, sowie den Zugriff auf die über Sie gespeicherten personenbezogenen Daten und zusätzliche Informationen anfordern</li>
				<li>Eine Kopie der personenbezogenen Daten, die Sie uns freiwillig bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format anfordern</li>
				<li>Eine Berichtigung der personenbezogenen Daten, die wir von Ihnen gespeichert haben, anfordern</li>
				<li>Das Löschen Ihrer personenbezogenen Daten beantragen </li>
				<li>Der Verarbeitung Ihrer personenbezogenen Daten durch uns widersprechen</li>
				<li>Die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten durch uns beantragen</li>
				<li>Eine Beschwerde bei einer Aufsichtsbehörde einreichen</li>
			</ol>
			<p>
				Beachten Sie jedoch, dass diese Rechte nicht uneingeschränkt gelten, sondern unseren eigenen rechtmäßigen Interessen sowie behördlichen Vorschriften unterliegen. 
			</p>
			<h6>Speicherung</h6>
			<p>
				Wir speichern Ihre personenbezogenen Daten so lange, wie es für die Bereitstellung unserer Services, die Einhaltung rechtlicher Verpflichtungen sowie die Beilegung von Streitigkeiten und die Durchsetzung unserer Richtlinien erforderlich ist. Die Aufbewahrungsfristen richten sich nach der Art der erfassten Daten und dem Zweck, für den diese Daten erfasst wurden, wobei sowohl die fallspezifischen Gegebenheiten als auch die Notwendigkeit berücksichtigt werden, veraltete, nicht genutzte Informationen baldmöglichst zu löschen. Datensätze mit personenbezogenen Daten von Kunden, Dokumente über die Kontoeinrichtung, Mitteilungen und andere Daten speichern wir gemäß geltender Gesetze und Vorschriften.
				<br />
				<br />
				Wir können jederzeit und in unserem alleinigen Ermessen unvollständige oder unrichtige Daten korrigieren, vervollständigen oder entfernen.
			</p>
			<h6>Cookies</h6>
			<p>
				Wir und unsere vertrauenswürdigen Partner verwenden Cookies und andere Technologien in unseren zugehörigen Services, einschließlich bei Ihrem Besuch unserer Webseite oder bei Ihrem Zugriff auf unsere Services. 
				<br />
				<br />
				Ein „Cookie“ ist eine kleine Dateieinheit, die eine Webseite Ihrem Gerät zuweist, während Sie diese aufrufen. Cookies sind sehr nützlich und können für verschiedene Zwecke verwendet werden. Dazu gehören die problemlose Seitennavigation, eine mögliche automatische Aktivierung von bestimmten Funktionen, die Speicherung Ihrer Einstellungen sowie eine schnelle und einfache Interaktion zwischen Ihnen und unseren Services. Cookies werden auch verwendet, um Ihnen relevante, für Sie interessante Werbeanzeigen einzublenden und Statistiken über Ihre Nutzung unserer Services zusammenzustellen.
				<br />
				<br />
				Auf der Webseite werden folgende Arten von Cookies eingesetzt:
			</p>
			<ol className="letters">
				<li><strong>„Session-Cookies“</strong> werden nur vorübergehend während einer Browsersitzung verwendet, um eine normale Systemnutzung zu ermöglichen. Diese Cookies werden von Ihrem Gerät gelöscht, sobald der Browser geschlossen wird.</li>
				<li><strong>„Persistente Cookies“</strong> werden nur von der Webseite gelesen, auf Ihrem Computer für einen festgelegten Zeitraum gespeichert und nicht gelöscht, wenn der Browser geschlossen wird. Solche Cookies werden verwendet, damit wir Sie bei einem wiederholten Besuch erkennen. Dadurch können wir beispielsweise Ihre Einstellungen für die nächste Anmeldung speichern.</li>
				<li><strong>„Cookies von Dritten“</strong>werden von anderen Onlineservices platziert, die Inhalte auf der angezeigten Seite ausführen, zum Beispiel von externen Analyseunternehmen, die den Zugriff auf unsere Webseite überwachen und analysieren.</li>
			</ol>
			<p>
				Cookies enthalten keine Daten, anhand derer Sie identifiziert werden können. Personenbezogene Daten, die wir von Ihnen speichern, werden möglicherweise von uns mit den in Cookies gespeicherten und von Cookies abgerufenen Daten verknüpft. Informationen zum Entfernen dieser Cookies stehen in den Anweisungen der Geräteeinstellungen. Beachten Sie, dass durch das Deaktivieren von Cookies einige Funktionen unserer Webseite möglicherweise nicht mehr ordnungsgemäß funktionieren und Ihre Online-Erfahrung dadurch möglicherweise eingeschränkt wird.
				<br />
				<br />
				Wir verwenden auch das Tool „Google Analytics“, um Daten über Ihre Nutzung der Webseite zu erfassen. Google Analytics erfasst beispielsweise, wie häufig die Webseite aufgerufen wird, welche Seiten bei einem Besuch aufgerufen werden usw. Wir verwenden die Google Analytics-Daten nur zur Verbesserung unserer Webseite und Services. Google Analytics erfasst die Ihnen am Tag Ihres Besuchs der Webseite zugewiesene IP-Adresse und nicht Ihren Namen oder andere identifizierbare Daten. Wir führen die erfassten Google Analytics-Daten nicht mit personenbezogenen Daten zusammen. Die Möglichkeiten von Google, die von Google Analytics erfassten Daten über Ihre Besuche auf dieser Webseite zu verwenden und freizugeben, unterliegen den Nutzungsbedingungen von Google Analytics und der Datenschutzerklärung von Google.
			</p>
			<h6>Erfassung von Daten durch Dritte</h6>
			<p>
				In dieser Richtlinie werden nur die Nutzung und Offenlegung von Daten, die wir von Ihnen erfassen, behandelt. Wenn Sie Daten auf anderen Webseiten veröffentlichen oder Dritten im Internet offenlegen, gelten möglicherweise andere Bestimmungen. Lesen Sie sich daher die allgemeinen Geschäftsbedingungen und Datenschutzbestimmungen immer sorgfältig durch, wenn Sie Daten offenlegen.
				<br />
				<br />
				Diese Datenschutzbestimmungen beziehen sich nicht auf Geschäftspraktiken von Unternehmen, die sich nicht in unserem Besitz befinden bzw. nicht unter unserer Kontrolle stehen, oder auf andere Personen als unsere Angestellten und Mitarbeiter, einschließlich Dritter, denen wir diese Daten wie in diesen Datenschutzbestimmungen beschrieben offenlegen.
			</p>
			<h6>Wie schützen wir Ihre Daten?</h6>
			<p>Wir setzen die Sicherheitsmaßnahmen auf der Webseite mit großer Sorgfalt um und schützen Ihre Daten. Wir verwenden in der Branche übliche Verfahren und Richtlinien, um den Schutz der erfassten und gespeicherten Daten sicherzustellen, und verhindern die unbefugte Verwendung solcher Daten. Wir verlangen außerdem von Dritten, dass sie sich gemäß diesen Datenschutzbestimmungen an ähnliche Sicherheitsanforderungen halten. Obwohl wir angemessene Schritte für den Schutz von Daten unternehmen, können wir nicht verantwortlich gemacht werden für Handlungen von jenen, die sich unbefugten Zugang zu unserer Webseite verschafft haben oder diese missbräuchlich verwenden, und wir geben keine ausdrückliche noch stillschweigende Gewähr, dass wir einen solchen Zugriff verhindern können.</p>
			<h6>Übermittlung von Daten außerhalb des Europäischen Wirtschaftsraums</h6>
			<p>
				Beachten Sie, dass einige Empfänger ihren Sitz möglicherweise nicht im Europäischen Wirtschaftsraum haben. Ist dies der Fall, werden wir Ihre Daten nur in von der Europäischen Kommission genehmigte Länder mit angemessenem Datenschutzniveau übermitteln oder durch eine rechtliche Vereinbarung ein angemessenes Datenschutzniveau sicherstellen.
			</p>
			<h6>Werbung</h6>
			<p>
				Wenn Sie auf unsere Webseite zugreifen, schalten wir möglicherweise Anzeigen mithilfe von Anzeigentechnologie externer Anbieter. Diese Technologie verwendet für die Anzeigenschaltung Ihre Nutzungsdaten der Services (z. B. durch die Platzierung von Drittanbieter-Cookies in Ihrem Webbrowser).
				<br />
				<br />
				Sie können sich von zahlreichen Anzeigennetzwerken Dritter abmelden, auch von Netzwerken, die von Mitgliedern der Network Advertising Initiative („NAI“) und der Digital Advertising Alliance („DAA“) betrieben werden. Informationen über die Vorgehensweise von NAI- und DAA-Mitgliedern, über Ihre Optionen, die Sie bezüglich der Verwendung solcher Daten von diesen Unternehmen haben, und darüber, wie Sie sich aus Anzeigennetzwerken Dritter, die von NAI- und DAA-Mitgliedern betrieben werden, abmelden können, finden Sie auf der jeweiligen Webseite: http://optout.networkadvertising.org/#!/ und http://optout.aboutads.info/#!/.
			</p>
			<h6>Marketing</h6>
			<p>
				Wir können Ihre personenbezogenen Daten wie Ihren Namen, Ihre E-Mail-Adresse, Ihre Telefonnummer usw. selbst verwenden oder an einen externen Untervertragsnehmer weiterleiten, um Ihnen Werbematerialien bezüglich unserer Services bereitzustellen, die Sie möglicherweise interessieren.
				<br />
				<br />
				Wir respektieren Ihr Recht auf Privatsphäre. Daher erhalten Sie in diesen Marketingmaterialien stets die Möglichkeit, sich von weiteren Zusendungen abzumelden. Wenn Sie sich abmelden, wird Ihre E-Mail-Adresse oder Telefonnummer aus unseren Marketing-Verteilerlisten entfernt.
				<br />
				<br />
				Beachten Sie, dass wir Ihnen auch nach einer Abmeldung vom Erhalt unserer Marketing-E-Mails weiterhin E-Mails mit wichtigen Informationen senden, die keine Abmeldeoption enthalten. Dazu gehören Wartungsmeldungen oder administrative Benachrichtigungen.
			</p>
			<h6>Unternehmenstransaktion</h6>
			<p>
				Wir können Daten im Fall einer Unternehmenstransaktion (z. B. beim Verkauf wesentlicher Unternehmensteile, bei einer Fusion, einer Konsolidierung oder einem Anlagenverkauf) weitergeben. Falls ein oben genannter Fall eintritt, übernimmt der Erwerber oder das entsprechende Unternehmen die in dieser Datenschutzerklärung dargelegten Rechte und Pflichten.
			</p>
			<h6>Minderjährige</h6>
			<p>
				Der Schutz der Daten von Kindern ist insbesondere im Online-Bereich sehr wichtig. Die Webseite ist nicht für Kinder konzipiert und richtet sich nicht an diese. Die Nutzung unserer Services durch Minderjährige ist nur mit der vorherigen Einwilligung oder Autorisierung von einem Elternteil oder Erziehungsberechtigten zulässig. Wir erfassen personenbezogene Daten von Minderjährigen nicht wissentlich. Wenn ein Elternteil oder Erziehungsberechtigter Kenntnis davon erlangt, dass sein oder ihr Kind uns personenbezogene Daten ohne deren Einwilligung bereitgestellt hat, kann er/sie sich unter info@buchbinder-security.de an uns wenden.
			</p>
			<h6>Aktualisierungen oder Änderungen dieser Datenschutzbestimmungen</h6>
			<p>
				Wir behalten uns das Recht vor, diese Datenschutzbestimmungen von Zeit zu Zeit zu ändern oder zu prüfen. Sie finden das Datum der aktuellen Version unter „Zuletzt geändert am“. Ihre fortgesetzte Nutzung der Plattform nach der Bekanntmachung solcher Änderungen auf unserer Webseite stellt Ihre Zustimmung zu solchen Änderungen an den Datenschutzbestimmungen dar und gilt als Ihr Einverständnis der Bindung an die geänderten Bestimmungen.
			</p>
			<h6>So erreichen Sie uns</h6>
			<p>
				Wenden Sie sich bei allgemeinen Fragen zur Webseite, zu den von uns über Sie erfassten Daten oder der Verwendung dieser Daten unter info@buchbinder-security.de an uns.
			</p>
		</div>
	)
}