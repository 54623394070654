import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../assets/images/logo.svg';
import deFlag from '../../assets/images/de-flag.svg';
import burger from '../../assets/images/burger.svg';
import cross from '../../assets/images/cross-b.svg'
import arrow from '../../assets/images/d-arrow.svg'
import pdf from '../../assets/images/pdf.svg';

export default class Header extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
        isPopupOpen: false
      }

      this.togglePopup = this.togglePopup.bind(this);
  }

  togglePopup() {
    this.setState({
      isPopupOpen: !this.state.isPopupOpen
    }); 
  }

  render() {
    return (
      <header>
      	<div className="container">
      		<div className="row">
      			<div className="md2 sm2 xs10 logo-box">
      				<Link to="/" className="logo">
      					<img src={logo} alt="" />
      				</Link>
      			</div>
            <div className="md9 sm9 xs0 menu-box">
      				<a href="/#leist" className="menu-item">Dienstleistungen</a>
      				<a href="/#warum" className="menu-item">Warum wir</a>
      				<a href="/#team" className="menu-item">Unser Team</a>
      				<a href="/#uber" className="menu-item">Über uns</a>
      				<a href="/#kontakt" className="menu-item">Kontakt</a>
      			</div>
      			<div className="flag-box sm1 xs0">
      				<div className="lang-switcher">
	              <div className="lang">
	              	<img src={deFlag} alt="" />
	              </div>
                <img className="arrow" src={arrow} alt="" />
      				</div>
      			</div>
            <div className="xl0 lg0 md0 sm0 xs2" onClick={this.togglePopup}>
              <img src={burger} alt="" className="burger" />
             </div>
      		</div>
      	</div>
        {this.state.isPopupOpen ? (<div className="popup-menu xl0 lg0 md0 sm0">
          <div className="cross" onClick={this.togglePopup}>
            <img src={cross} alt="" />
          </div>
          <div className="container">
            <div className="lang-box">
              <span className="">Sprache</span>
              <div className="lang-switcher">
                <div className="lang">
                  <img src={deFlag} alt="" />
                </div>
                <img className="arrow" src={arrow} alt=""/>
              </div>
            </div>
            {this.props.isPdf ? (
              <div className="pdf">
                <a href="/files/agb.pdf" target="_blank">
                  <img src={pdf} alt="" />
                  <span className="big">Download-AGB</span>
                  <span className="lil">(120 KB)</span>
                </a>
              </div> ) : null
            }
            <div className="menu-box">
              <a href="/#leist" className="menu-item" onClick={this.goToSec}>Dienstleistungen</a>
              <a href="/#warum" className="menu-item" onClick={this.goToSec}>Warum wir</a>
              <a href="/#team" className="menu-item" onClick={this.goToSec}>Unser Team</a>
              <a href="/#uber" className="menu-item" onClick={this.goToSec}>Über uns</a>
              <a href="/#kontakt" className="menu-item" onClick={this.goToSec}>Kontakt</a>
            </div>
          </div>
          <div className="container cont2">
            <div className="links-box">
              <Link to="/datenschutz/">Datenschutz</Link>
              <Link to="/impressum/">Impressum</Link>
              <Link to="/allgemeine/">Allgemeine Geschäftsbedingungen</Link>
            </div>
          </div>
        </div>) : null}
      </header>
    )
  }
}