import React from 'react';
import CustomScroll from 'react-custom-scroll';
import email from '../../assets/images/email.svg';
import foto1 from '../../assets/images/foto1.jpg';
import foto2 from '../../assets/images/foto2.jpg';
import cross from '../../assets/images/cross.svg';
import arrow from '../../assets/images/arrow.svg';
import sponsor1 from '../../assets/images/sponsor1.jpg';
import sponsor2 from '../../assets/images/sponsor2.jpg';

export default class Team extends React.Component {
    constructor() {
        super();

        this.state = {
            isBio1Shows: false,
            isBio2Shows: false,
        }

        this.showBio = this.showBio.bind(this);
        this.closeBio = this.closeBio.bind(this);
    }

    showBio(bioNumber) {
        const self = this;
        return function() {
            if(bioNumber === 'bio1') {
                self.setState({
                    isBio1Shows: !self.state.isBio1Shows
                });
            } else {
                self.setState({
                    isBio2Shows: !self.state.isBio2Shows
                });
            }
        }
    }

    closeBio() {
        this.setState({
            isBio1Shows: false,
            isBio2Shows: false,
        })
    }


    render() {
        return (
            <section className="team" id="team">
                <div className="container">
                    <div className="row row-sec-head row1">
                        <div className="xs12">
                            <h3>DAS TEAM</h3>
                            <div className="desc">
                                Lernen Sie unsere Mitarbeiter kennen. Unsere Arbeit ist unsere Leidenschaft.
                            </div>
                        </div>
                    </div>
                    <div className="row row2">
                        <div className="xl2 lg1 md1 sm0 xs0"></div>
                        <div className="xl4 lg5 md5 sm6 xs12 bio-col">
                            <div className="bio-box">
                                <img src={foto1} alt="" />
                                <div className="bio-info">
                                    <div className="email">
                                        <img src={email} alt="" />
                                    </div>
                                    <div className="name">Jewgeny Buchbinder</div>
                                    <div className="position">Director</div>
                                </div>
                                <div className="showBio" onClick={this.showBio('bio1')}> 
                                    <span>Weiterlesen</span>
                                    <img src={arrow} alt="" />
                                </div>

                                {this.state.isBio1Shows ? (
                                <div className="modal-bio modal-bio1">
                                    <div className="close" onClick={this.closeBio}>
                                        <img src={cross} alt="" />
                                    </div>
                                    <CustomScroll heightRelativeToParent="100%" >
                                        <div className="name">Jewgeny Buchbinder</div>
                                        <div className="position">Director</div>
                                       
                                        <div className="bio-text">
                                            Schon in seiner frühen Kindheit begeisterte sich Jewgeny Buchbinder für den Kampfsport und probierte sich im Judo. Seine schulische Ausbildung erhielt er an der Potsdamer Elite-Sportschule „Friedrich-Ludwig-Jahn“, die er mit dem Abitur abgeschlossen hat. Ab dem 16. Lebensjahr wurde er bereits zu einem gefragten Trainer des national bekannten Judoclub UJKC Potsdam und unterrichtete seither. Sein Fokus lag dabei auf kind- und jugendgerechten Judotechniken. 
                                            <br />
                                            <br />
                                            Seit 2012 erlangte Jewgeny Buchbinder anerkannte Trainerlizenzen A, B und C im Leistungssport. Als Trainer zeichnete er sich durch seine humorvolle, bodenständige und einfühlsame Arbeitsweise aus, die immer sehr praxisbezogen auf die sportliche Entwicklung seiner Kursteilnehmer eingeht und sich bei Allen deshalb großer Beliebtheit erfreut. 
                                            <br />
                                            <br />
                                            Während seiner Sportlaufbahn nahm  Jewgeny an zahlreichen nationalen und internationalen Wettbewerben teil und besitzt viele Pokale und Auszeichnungen. Im Juni 2009 feierte er seinen ersten Bundesligasieg. Auf internationaler Ebene gewann Jewgeny die Judo-Einzelmeisterschaft bei dem größten jüdischen Sportfest Makkabiade in Israel. So war für ihn klar, dass er gerne seine Leidenschaft zum Beruf machen würde.
                                            <br />
                                            <br />
                                            2014 hat Jewgeny Buchbinder sich selbständig gemacht und die „Buchbinder Security“ in Potsdam gegründet. Das junge Unternehmen wurde durch sein umfangreiches Wissen und angesammelten Erfahrungsschatz bereichert und ist mittlerweile im Raum Berlin-Brandenburg bekannt geworden. 
                                            <br />
                                            <br />
                                            Im Jahr 2018 hat Jewgeny Buchbinder die Ausbildereignungsprüfung nach AEVO bestanden und beginnt demnächst sein Meisterstudium. So wird er seine Kompetenzen in eigenes Ausbildungszentrum effektiv einbringen, um das Wachstum des Unternehmens weiter auszubauen. 
                                        </div>
                                    </CustomScroll>
                                </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="xl4 lg5 md5 sm6 xs12 bio-col">
                            <div className="bio-box">
                                <img src={foto2} alt="" />
                                <div className="bio-info">
                                    <div className="email">
                                        <img src={email} alt="" />
                                    </div>
                                    <div className="name">Stanislav Korsunski</div>
                                    <div className="position">Disponent</div>
                                </div>
                                <div className="showBio" onClick={this.showBio('bio2')}> 
                                    <span>Weiterlesen</span>
                                    <img src={arrow} alt="" />
                                </div>

                                {this.state.isBio2Shows ? (
                                <div className="modal-bio modal-bio2">
                                    <div className="close" onClick={this.closeBio}>
                                        <img src={cross} alt="" />
                                    </div>
                                    <div className="name">Stanislav Korsunski</div>
                                    <div className="position">Disponent</div>
                                    <div className="bio-text">
                                        Terminliche Koordination mit den Kunden, Einsatzplanung des gesamten Teams sowie Überprüfung der Stundenverbuchungen im Unternehmen übernimmt Stanislav Korsunski. 
                                        <br />
                                        Dank seiner langjährigen Berufserfahrung, Kompetenz und Zuverlässigkeit werden die Mitarbeitereinsätze immer pünktlich, präzise und einwandfrei umgesetzt. 
                                        <br />
                                        Mit seinem Organisations- und Koordinationsgeschick stärkt Stanislav Korsunski den Teamgeist und fördert das Mitarbeiterengagement. 
                                    </div>
                                </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="xl2 lg1 md1 sm0 xs12"></div>
                    </div>
                    <div className="row row-sec-head row3">
                        <div className="xs12">
                            <h3>Sponsoring</h3>
                            <div className="desc">
                                Als mittelständisches Unternehmen ist es für uns eine Selbstverständlichkeit auch gesellschaftliches Engagement zu zeigen. Aktuell unterstützen wir aktiv durch Sponsoring 
                            </div>
                        </div>
                    </div>
                    <div className="row row4">
                        <div className="xl1 lg0 md0 sm0 xs0"></div>
                        <div className="xl5 lg6 md6 sm6 xs12">
                            <img src={sponsor1} alt="" />
                            <div className="img-desc">Brandenburg Adler Rugby Oldies</div>
                        </div>
                        <div className="xl5 lg6 md6 sm6 xs12">
                            <img src={sponsor2} alt="" />
                            <div className="img-desc">Jana Ritter`s Tanzstudio, welches in der Kinder- und Jugendarbeit aktiv ist.</div>
                        </div>
                        <div className="xl1 lg0 md0 sm0 xs0"></div>
                    </div>
                </div>
            </section>
        )
    }
}