import React from 'react';
import clock from '../../assets/images/clock.svg';
import shield from '../../assets/images/shield.svg';
import flex from '../../assets/images/flex.svg';
import sign from '../../assets/images/sign.svg';

export default function Advantages() {
    return (
        <section className="adv">
            <div className="container">
                <div className="row">
                    <div className="md3 sm6 xs12 adv-item">
                        <div className="img-col">
                            <img src={clock} alt="" />
                        </div>
                        <div className="text-col">
                            <h5>Pünktlich</h5>
                            <p>Wir sind mindestens 30 Minuten vor Dienstbeginn am Einsatzort</p>
                        </div>
                    </div>
                    <div className="md3 sm6 xs12 adv-item">
                        <div className="img-col">
                            <img src={shield} alt="" />
                        </div>
                        <div className="text-col">
                            <h5>Zuverlässig</h5>
                            <p>Jeder Auftrag wird pflichtbewusst zu Ihrer Zufriedenheit erfüllt</p>
                        </div>
                    </div>
                    <div className="md3 sm6 xs12 adv-item">
                        <div className="img-col">
                            <img src={flex} alt="" />
                        </div>
                        <div className="text-col">
                            <h5>Flexibel</h5>
                            <p>Wir passen uns auch kurzfristig Ihrer individuellen Situation an</p>
                        </div>
                    </div>
                    <div className="md3 sm6 xs12 adv-item">
                        <div className="img-col">
                            <img src={sign} alt="" />
                        </div>
                        <div className="text-col">
                            <h5>Gewissenhaft</h5>
                            <p>Ihren Weisungen folgen wir stets freundlich und gewissenhaft</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}