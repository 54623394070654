import React from 'react';

export default function Allgemeine() {
	return (
		<div className="text-box">
			<h5>Allgemeine Geschäftsbedingungen</h5>
			<div className="desc">für die Abwicklung von Sicherheitsdienstleistungen durch die Buchbinder Security</div>
			<h6>1. Geltungsbereich</h6>
			<p>
				Diese Allgemeinen Geschäftsbedingungen gelten für Leistungen der Buchbinder
				Security („Buchbinder“) im Bereich Sicherheit. Abweichende oder
				zusätzliche Bedingungen des Auftraggebers erkennt Buchbinder nicht an, es sei
				denn Buchbinder erklärt sich schriftlich und ausdrücklich mit ihrer Geltung
				einverstanden. Die stillschweigende Annahme von Aufträgen oder Leistung an den
				Auftraggeber bedeuten daher kein Einverständnis mit den entgegenstehenden
				Bedingungen des Auftraggebers. 
			</p>
			<h6>2. Angebote, Vertragsabschlüsse und -änderungen</h6>
			<p>
				2.1 Der Vertrag kommt in dem Zeitpunkt zustande, in welchem dem Auftraggeber
				die Auftragsbestätigung durch Buchbinder zugeht. Mündliche Angebote oder
				Nebenabreden oder nachträgliche Änderungen/Ergänzungen der von Buchbinder
				angebotenen Leistung gelten nur, wenn sie von Buchbinder schriftlich bestätigt
				werden.
				<br />
				<br />
				2.2 Angebote von Buchbinder verlieren ihre Wirksamkeit, wenn sie nicht beim
				Auftraggeber gegenüber Buchbinder schriftlich angenommen werden. 
			</p>
			<h6>3. Preise und Preisangaben</h6>
			<p>
				Alle Preise sind Nettopreise, zuzüglich der jeweils geltenden Umsatzsteuer (derzeit
				19%). Preisangaben sind ohne sonstige, eventuell anfallende öffentlich-rechtliche
				Nebenkosten. Sollten solche Kosten anfallen, werden sie hinzuberechnet.
			</p>
			<h6>4. Leistungsinhalt </h6>
			<p>
				4.1 Das Wach- und Sicherheitsgewerbe ist gemäß § 34a Gewerbeordnung ein
				erlaubnispflichtiges Gewerbe und übt seine Sicherheitsdienstleistung als
				Revierwach-, Separatwach- oder Sonderdienst aus.
			</p>
			<div className="offset">
				4.1.1 Der Revierwachdienst erfolgt in Dienstkleidung durch Einzelstreifen
				oder Funkstreifenfahrer. Es werden dabei – soweit nichts anders vereinbart
				ist – bei jedem Rundgang Kontrollen der in Wachrevieren
				zusammengefassten Wachobjekte zu möglichst unregelmäßigen Zeiten 
				<br />
				<br />
				4.1.2 Der Separatwachdienst erfolgt in der Regel durch eine(n) oder
				mehrere Wachmänner/-frau(en) oder Pförtner/innen, die eigens für ein bzw.
				wenige in einem räumlichen Zusammenhang stehende Wachobjekte eingesetzt sind. Die einzelnen Tätigkeiten werden in besonderen
				Dienstanweisungen festgelegt.
				<br />
				<br />
				4.1.3 Zu den Sonderdiensten gehören z. B. Personalkontrollen,
				Personenbegleit- und Schutzdienste, Geld- und Wertdienste,
				Sicherungsposten, der Betrieb von Alarm- und Notrufzentralen
				(Dienstleistungszentralen) sowie die Durchführung von Kassen-,
				Ordnungs- und Aufsichtsdiensten für Ausstellungen, Messen,
				Veranstaltungen und andere Dienste. 
			</div>
			<p>
				4.2 Die gegenseitigen Verpflichtungen von Auftraggeber und Buchbinder werden in besonderen Verträgen vereinbart.
				<br />
				<br />
				4.3 Angaben und Beschreibungen (Qualitäten) im Angebot gelten ab dem
				Zeitpunkt, an dem Auftraggeber die schriftliche Auftragsbestätigung zugeht, als
				verbindlich vereinbart.
			</p>
			<h6>5. Begehungsvorschrift</h6>
			<p>
				5.1 Im Einzelfall ist für die Ausführung des Dienstes allein die schriftliche
				Begehungsvorschrift/der Alarmplan maßgebend. Sie enthält den Anweisungen des
				Auftraggebers entsprechend, die näheren Bestimmungen über die Rundgänge,
				Kontrollen und die sonstigen Dienstverrichtungen, die vorgenommen werden
				müssen.
				<br />
				<br />
				5.2 Änderungen und Ergänzungen der Begehungsvorschrift/des Alarmplanes
				bedürfen der schriftlichen Vereinbarung. Soweit unvorhersehbare Notstände es
				erfordern, kann in Einzelfällen von vorgesehenen Kontrollen, Rundgängen und
				sonstigen Dienstverrichtungen Abstand genommen werden.
			</p>
			<h6>6. Schlüssel und Notfallanschriften</h6>
			<p>
				6.1 Die für den Dienst erforderlichen Schlüssel sind vom Auftraggeber rechtzeitig und kostenlos zur Verfügung zu stellen. 
				<br />
				<br />
				6.2 Der Auftraggeber gibt Buchbinder die Anschriften bekannt, die bei einer
				Gefährdung des Objektes auch nachts telefonisch benachrichtigt werden können.
				Anschriftenänderungen müssen Buchbinder umgehend mitgeteilt werden. In den
				Fällen, in denen Buchbinder über aufgeschaltete Alarmanlagen die
				Alarmverfolgung durchzuführen hat, ist vom Auftraggeber die Benachrichtigungsreihenfolge anzuordnen.
			</p>
			<h6>7. Leistungsänderungen / Preisänderungen </h6>
			<p>
				7.1 Buchbinder behält sich Änderungen zum Angebot für den Fall vor, dass aus
				nicht von Buchbinder zu vertretenen Gründen Teile des Angebots durch andere
				gleichwertige Leistungen ersetzt werden müssen. In diesem Fall wird Buchbinder den Auftraggeber rechtzeitig über die Änderungen informieren und eine dem
				ursprünglichen Angebot möglichst gleichwertige Leistung anbieten.
				<br />
				<br />
				7.2 Hat der Auftraggeber Bedenken gegen die Änderungen, so hat er sie dem
				Auftraggeber unverzüglich schriftlich mitzuteilen. Teilt Buchbinder die Bedenken
				nicht, so ist Buchbinder für seine Angaben und Anordnungen im Hinblick auf die
				Leistungsänderung verantwortlich. 
				<br />
				<br />
				7.3 Änderungen seitens des Auftraggebers sind Buchbinder vor deren Ausführung,
				unter Einhaltung einer Frist von 4 Wochen, schriftlich anzukündigen. Änderungen
				sind nur mit vorheriger schriftlicher Zustimmung durch Buchbinder möglich.
				<br />
				<br />
				7.4 Wird eine im Vertrag nicht vorgesehene Leistung gefordert, so hat Buchbinder Anspruch auf gesonderte Vergütung.
				<br />
				<br />
				7.5 Im Falle der Veränderung/Neueinführung von gesetzlichen Steuern, Abgaben,
				Versicherungsprämien, Kfz-Betriebskosten, Lohnkosten und Lohnnebenkosten,
				insbesondere durch den Abschluss neuer Lohn-, Mantel- oder sonstiger
				Tarifverträge, ist das Entgelt um den Betrag in gleicher Weise zu verändern, um
				den sich durch die Veränderung der Lohnkosten, Lohnnebenkosten und sonstigen
				o.g. Kosten der Stundenverrechnungssatz für die Ausführung des Auftrages
				geändert hat, zuzüglich der jeweils gültigen gesetzlichen Steuern und Abgaben.
			</p>
			<h6>8. Unterbrechung der Bewachung</h6>
			<p>
				8.1 Bei Unterbrechungen der Bewachung, die Buchbinder nicht zu vertreten hat,
				insbesondere Ablauf- und Betriebsstörungen, die auf höherer Gewalt beruhen
				(Streik, Aussperrung, Naturkatastrophen, Gewalttaten oder Anschläge o. ä.) oder
				Ablaufstörungen, die auf den Auftraggeber zurückzuführen sind, kann Buchbinder
				die Leistungserbringung unterbrechen oder zweckentsprechend umstellen.
				<br />
				<br />
				8.2 Im Falle der Unterbrechung verpflichtet sich Buchbinder, das Entgelt
				entsprechend den etwa ersparten Löhnen für die Zeit der Unterbrechung zu
				ermäßigen. Weitergehende Ansprüche des Auftraggebers – insbesondere auch auf
				den Ersatz von Aufwendungen oder Schäden - bestehen nicht.
				<br />
				<br />
				8.3 Wenn die Störungen länger als drei Monate andauern, sind die Parteien berechtigt vom Vertrag zurückzutreten.
			</p>
			<h6>9. Beanstandungen</h6>
			<p>
				9.1 Beanstandungen des Auftraggebers wegen Mängeln oder anderen Abweichungen vomVertrag sind detailliert und unverzüglich nach Feststellung und
				Auftragsdurchführung schriftlich zu rügen. Bei nicht rechtzeitig erbrachter
				Mängelrüge gelten die erbrachten Leistungen als genehmigt.
				<br />
				<br />
				9.2 Wiederholte oder grobe Verstöße in der Ausführung des Dienstes berechtigen
				nur dann zur fristlosen Kündigung des Vertrages, wenn das Unternehmen nach Benachrichtigung in Textform nicht in angemessener Zeit – spätestens innerhalb
				von sieben Werktagen – für Abhilfe sorgt, soweit diese möglich und für beide
				Vertragspartner zumutbar ist.				
			</p>
			<h6>10. Leistungen Dritter</h6>
			<p>
				Buchbinder ist berechtigt, in Übereinstimmung mit dem Auftraggeber, sich zur
				Erfüllung seiner Verpflichtungen Unternehmen zu bedienen, die die
				Gewerbeerlaubnis gemäß §34a GewO besitzen und zuverlässig sind.
			</p>
			<h6>11. Rechnungsstellung, Zahlungsbedingungen</h6>
			<p>
				11.1 Sämtliche Entgelte sind ohne Abzug innerhalb von 14 Tagen nach Rechnungsstellung an Buchbinder zu zahlen. 
				<br />
				<br />
				11.2 Kommt der Auftraggeber mit dem Ausgleich der Rechnungen in Verzug, ist
				Buchbinder berechtigt, alle Leistungen, insbesondere auch vorbereitende
				Leistungen einzustellen oder ggf. vom Vertrag zurückzutreten. Während der
				Leistungsruhezeit sind Fortzahlungen zu leisten. Für den Verzugszeitraum werden
				Verzugszinsen in Höhe von 3 % Basiszinssatz berechnet. Schadensersatzansprüche der Buchbinder bleiben davon unberührt. 
				<br />
				<br />
				11.3. Zahlungen gelten erst mit der Gutschrift auf einem unserer Konten als
				vorgenommen. Dabei anfallende Gebühren oder Kosten – insbesondere bei
				Zahlungen oder Überweisungen aus dem Ausland – gehen zu Lasten des
				Zahlungspflichtigen.
			</p>
			<h6>12. Ausfallschäden</h6>
			<p>
				Bei Stornierungen des Auftraggebers innerhalb von 14 Tagen vor vorgesehenem
				Dienstbeginn ist Buchbinder berechtigt eine Ausfallgebühr i. H. v. 30% des
				Auftragsvolumens zu verlangen. Dieses erhöht sich bei einer Stornierung zwischen
				dem 14. und 7. Tag auf 45% dessen. Innerhalb der letzten 7 Tage vor
				Auftragsbeginn werden 70% des Auftragsvolumens an Stornogebühren erhoben.
				Sollte ein solcher Rücktritt vom Vertrag erst am Tage des vereinbarten
				Auftragsbeginns erfolgen, so ist Buchbinder berechtigt, das Entgelt in Höhe von
				100% des Auftragsvolumens zu verlangen. Dem Auftraggeber bleibt vorbehalten,
				den Nachweis zu erbringen, dass kein oder ein geringerer Schaden entstanden ist.
			</p>
			<h6>13. Aufrechnung</h6>
			<p>Eine Aufrechnung gegen unsere Zahlungsansprüche ist unzulässig.</p>
			<h6>14. Haftung</h6>
			<p>
				14.1 Buchbinder unterhält für alle im Vertrag vereinbarten Leistungen gemäß § 6 Bewachungsverordnung eine Haftpflichtversicherung mit folgenden Deckungssummen – s. Anlage (Haftpflichtversicherungsschein)
				<br />
				<br />
				14.2 Der Auftraggeber haftet für alle Schäden an Gegenständen, die Buchbinder
				eingebracht hat und die durch den Auftraggeber, Mitarbeiter des Auftraggebers
				oder sonstige Dritte verursacht wurden.
			</p>
			<h6>15. Hausrecht</h6>
			<p>Das Personal der Buchbinder hat während der Dienstzeit das Hausrecht in gleichen Umfang wie der Auftraggeber. </p>
			<h6>16. Personal/ Abwerbungsverbot </h6>
			<p>
				16.1 Das Weisungsrecht über die Mitarbeiter von Buchbinder obliegt ausschließlich Buchbinder außer Gefahr von Leib und Leben. 
				<br />
				<br />
				16.2 Dem Auftraggeber ist es nicht gestattet, Mitarbeiter von Buchbinder zur
				Auflösung ihres Arbeitsverhältnisses und zur Begründung eines neuen Dienst- oder
				Arbeitsverhältnisses als selbständige oder unselbständige Mitarbeiter des
				Auftraggebers zu veranlassen. Diese Bestimmung gilt auch noch sechs Monate
				nach Beendigung des Vertrages. 
				<br />
				<br />
				16.3 Verstößt der Auftraggeber gegen die Bestimmungen der Ziffer 16.2, so ist er verpflichtet, die achtfache Monatsgebühr als Vertragsstrafe zu zahlen. 
			</p>
			<h6>17. Datenschutz/ Geheimhaltung</h6>
			<p>
				17.1 Für die Verarbeitung und den Schutz personenbezogener Daten gelten im
				Rahmen des Vertragsverhältnisses die Bestimmungen der Verordnung (EU)
				2016/679 (Datenschutzgrundverordnung (DSGVO)) und des
				Bundesdatenschutzgesetztes (BDSG) in ihrer jeweils gültigen Fassung.
				<br />
				<br />
				17.2 Insbesondere gelten Art. 5 Abs. 1 lit. F, Art. 28 Abs. 3 DSGVO (Integrität und
				Vertraulichkeit der Daten) sowie Art. 12 ff. DSGVO (Informationspflichten).
			</p>
			<h6>18. Verbraucherstreitbegleitung</h6>
			<p>
				Das Unternehmen ist nicht verpflichtet und nicht bereit an einem
				Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle im Sinne des
				§36 Abs. 1 Verbraucherstreitbeilegungsgesetz (VSBG) teilzunehmen. Davon
				unberührt bleibt die Möglichkeit der Streitbeteiligung durch eine
				Verbraucherschlichtungsstelle im Rahmen einer konkreten Streitigkeit bei
				Zustimmung beider Vertragsparteien (§37 VSBG).
			</p>
			<h6>19. Schlussbestimmungen</h6>
			<p>
				19.1 Unsere Vereinbarungen mit dem Auftraggeber unterliegen ausschließlich
				deutschem Recht, soweit nicht nach den unabdingbaren Regelungen des
				internationalen Privatrechts zwingend anderes Recht gilt. 
				<br />
				<br />
				19.2 Gerichtstand für alle Auseinandersetzungen im Zusammenhang mit unseren
				Leistungen ist Potsdam, soweit der Auftraggeber Kaufmann im Sinne des HGB,
				juristische Person des öffentlichen Rechts o. ä. ist und nicht etwas anders rechtlich
				wirksam vereinbart wird. 
				<br />
				<br />
				19.3 Sollte eine Bestimmung dieser Bedingungen oder der Teil einer Bedingung
				unwirksam sein oder werden, so bleiben die übrigen Bestimmungen bzw. der
				übrige Teil der Bestimmungen wirksam. Und die unwirksame Bestimmung soll dem
				wirksamen nahekommen.
			</p>
		</div>
	)
}