import React from 'react';
import cross from '../../assets/images/cross.svg';
import headset from '../../assets/images/headset.svg';
 
export default function ModalLil(props) {
	return (
		<div className="modal modal-overlay">
			<div className="modal-win modal-lil">
				<div className="cross">
					<img src={cross} alt="" onClick={props.closeLilForm} />
				</div>
				<div className="icon-box">
					<img src={headset} alt="" />
				</div>
				<h3>Danke Ihnen!</h3>
				<div className="desc">
					Die Anfrage wurde gesendet. Wir werden uns in Kürze bei Ihnen melden.
				</div>
				<a href="/" className="button" onClick={props.closeLilForm}>zurück zur Homepage</a>
			</div>
		</div>
	)
}