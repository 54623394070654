import React from 'react';
import uber1 from '../../assets/images/uber1.jpg';
import uber2 from '../../assets/images/uber2.jpg';
import LilForm from './LilForm';

export default function Advantages(props) {
    return (
        <section className="uber-uns" id="warum">
            <img className="xl0 lg0 md0 sm0 full-width" src={uber1} alt="" />
            <div className="container">
                <div className="row row-sec-head row1">
                    <div className="xs12">
                        <h3>WISSENSWERTES ÜBER UNS</h3>
                        <div className="desc">Vertraulichkeit und Loyalität bilden das Fundament unserer Arbeit.</div>
                    </div>
                </div>
                <div className="row row2">
                    <div className="md6 sm5 xs0 img-col">
                        <img src={uber1} alt="" />
                    </div>
                    <div className="md6 sm7 xs12 text-col">
                        <h5>
                            Wir bieten ein breites Leistungsspektrum an welches auf Ihre individuellen Bedürfnisse abgestimmt wird.
                        </h5>
                        <p>
                            Unsere angestellten Mitarbeiter verfügen über langjährige Berufserfahrung und werden regelmäßig intern geschult, um den hohen Anforderungen unserer Kunden jederzeit gerecht werden zu können. Wir arbeiten ausschließlich mit eigenem Personal. 
                            <br className="xs0"/>
                            Gerne beraten wir Sie persönlich und erstellen Ihnen ein individuelles Angebot.
                        </p>
                        <div className="list">
                            <div className="list-item">
                                Eine unverbindliche und für Sie kostenfreie Beratung in allen relevanten Dienstleistungsbereichen
                            </div>
                            <div className="list-item">
                                Entwicklung eines Sicherheitskonzepts basierend auf Ihren tatsächlichen Bedürfnissen
                            </div>
                            <div className="list-item">
                                Langjährige Erfahrung sowie Fachkompetenz in allen Belangen der Wach- und Sicherheitsdienstleistung
                            </div>
                            <div className="list-item">
                                Eine Schwachstellenanalyse für Ihr Objekt oder Veranstaltung
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row3">
                    <div className="md6 sm5 xs0 image-col">
                        <img src={uber2} alt="" />
                    </div>
                    <div className="md6 sm7 xs12 text-col">
                        <h3>Überlassen Sie Ihre Sicherheit nicht dem Zufall sondern den Experten von der Buchbinder Security</h3>
                        <p>
                            Bestellen Sie den Event- oder Objektschutz, wann immer Sie ihn benötigen. Unser Team kann jede große oder kleine Veranstaltung organisieren und schützen, sodass Sie sicher sein können, dass alles in Ordnung ist.
                        </p>
                        <LilForm sendLilForm={props.sendLilForm}/>
                    </div>
                </div>
            </div>
        </section>
    )
}