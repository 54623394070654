import React from 'react';

export default function FirstScreen() {
    return (
        <section className="first-screen">
        	<div className="container">
        		<div className="row">
        			<div className="xl6 lg6 md6 sm0 xs0"></div>
        			<div className="xl6 lg6 md6 sm12 xs12 text-col">
        				<h1>Überlassen Sie Ihre Sicherheit nicht dem Zufall sondern den Experten von der Buchbinder Security</h1>
        				<p className="text">
							Bestellen Sie den Event- oder Objektschutz, wann immer Sie ihn benötigen. Unser Team kann jede große oder kleine Veranstaltung organisieren und schützen, sodass Sie sicher sein können, dass alles in Ordnung ist
						</p>
						<a href="/#form" className="button goto">Bestellen Sie uns</a>
        			</div>
        		</div>
        	</div>
        </section>
    )
}