import React from 'react';
import { Link } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import Datenschutz from '../texts/Datenschutz';
import Allgemeine from '../texts/Allgemeine';
import arrow from '../../assets/images/arrow.svg';
import pdf from '../../assets/images/pdf.svg';

export default class Text extends React.Component {
    constructor() {
        super()
        
        this.state = {

        }
    }

    render() {
        const page = this.props.location.pathname;

        return(
            <div className="text-page">
                <Header isPdf={page === "/datenschutz/" ? false : true} isHomePage={false} />
                <section className="text-sec">
                    <div className="container">
                        <div className="row">
                            <div className="lg2 md1 sm0 xs0" />
                            <div className="xl6 lg7 md10 sm12 xs12 text-col">
                                <Link to="/" className="link-back">
                                    <img src={arrow} alt="" />
                                    <span>Zurück</span>
                                </Link>
                                {page === "/datenschutz/" ? <Datenschutz /> : <Allgemeine />}
                            </div>
                            <div className="xl4 lg3 md3 sm2 xs0 pdf-col">
                                {page === "/datenschutz/" ? null : (
                                    <a href="/files/agb.pdf" className="pdf-link" target="_blank">
                                        <img src={pdf} alt="" />
                                        <span className="big">Download-AGB </span>
                                        <span className="lil">(120 KB)</span>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}